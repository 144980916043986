<template>
  <div class="container">
    <div class="search">
      <div class="search-first">
        <div class="search-item">
          <div class="search-item-name">客户昵称：</div>
          <el-input
            placeholder="请输入客户昵称"
            prefix-icon="el-icon-search"
            v-model="search.name"
          >
          </el-input>
        </div>
        <div class="search-item">
          <div class="search-item-name">手机号码：</div>
          <el-input
            placeholder="请输入手机号码"
            prefix-icon="el-icon-search"
            v-model="search.phone"
          >
          </el-input>
        </div>
        <div class="search-item">
          <div class="search-item-name">提问时间：</div>
          <el-date-picker
            clearable
            v-model="search.range"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
    </div>

    <div class="content">
      <el-table ref="multipleTable" :data="records" style="width: 100%">
        <el-table-column prop="nickName" width="140" label="基本信息">
          <template slot-scope="scope">
            <div class="basic">
              <img :src="scope.row.imgUrl" alt="暂无" />
              <div>
                <div>{{ scope.row.nickName }}</div>
                <div>ID：{{ scope.row.id }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="phoneNumber" label="电话"> </el-table-column>
        <el-table-column label="提问时间">
          <template slot-scope="scope">
            {{ dealDate(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="questionType" label="意见标题">
        </el-table-column>
        <el-table-column prop="content" label="意见内容"> </el-table-column>
        <el-table-column
          align="center"
          prop="operation"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="deleteOne(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageOptions"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import { pageOptions } from '@/utils/config'
import * as feedbackService from '@/api/feedback'
import moment from 'moment'

const init = {
  name: null,
  phone: null,
  active: null,
  range: ['', '']
}

export default {
  data () {
    return {
      search: R.clone(init),
      records: [],
      total: 0,
      pageOptions: pageOptions,
      pageSize: 10,
      currentPage: 1
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fmtDate (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    fmt (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    dealDate (data) {
      return new Date(data)
    },
    async fetchData (page = 1, size = 10, search = null) {
      try {
        const query = search
          ? {
            type: 0,
            nickName: search.name,
            phone: search.phone,
            startDate: search.range[0] ? this.fmt(search.range[0]) : null,
            endDate: search.range[1] ? this.fmt(search.range[1]) : null,
            pageNo: page - 1,
            pageSize: size
          }
          : {
            type: 0,
            pageNo: page - 1,
            pageSize: size
          }
        const res = await feedbackService.fetchList(query)
        this.records = res.records
        this.total = res.total
      } catch (err) {
        // handle error
      }
    },
    async deleteOne (id) {
      try {
        await feedbackService.deleteOne({ idList: [id] })
        this.$notify.success({ title: '删除成功' })
        this.fetchData(this.currentPage, this.pageSize, this.search)
      } catch (err) {
        this.$notify.error({ title: '删除失败' })
      }
    },
    handleSearch () {
      this.fetchData(this.currentPage, this.pageSize, this.search)
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.fetchData(this.currentPage, val, this.search)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData(val, this.pageSize, this.search)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.content {
  margin-top: 10px;
  background: #ffffff;
  padding: 10px;
}
.pagination {
  margin-top: 30px;
  text-align: center;
}

.search {
  padding: 30px;
  margin: 10px auto;
  width: 100%;
  background: white;

  &-first {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    padding-bottom: 20px;
  }
  &-item {
    display: grid;
    grid-template-columns: 80px auto;
    align-items: center;
  }
}

.basic {
  display: grid;
  grid-template-columns: 40px 100px;
  grid-gap: 10px;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
